import { useMemo } from 'react';

import { folderTypeCategories } from '../constants';

interface IFolderBase {
  typeFolder: 1 | 2 | 3 | 4 | 5;
  userCreateId?: number;
  id?: number;
}

interface IAllowedFolders {
  allowCreate: boolean;
  allowEditAndDelete: boolean;
  allowMove: boolean;
}

interface IAllowedFoldersProps<T extends IFolderBase> {
  folderData?: T;
  userId?: number;
  isAdmin: boolean;
  defaultFolderId?: number;
}
export const useCanDocuments = <T extends IFolderBase>({
  userId,
  isAdmin,
  folderData,
  defaultFolderId,
}: IAllowedFoldersProps<T>): IAllowedFolders => {
  const allowCreate = useMemo(() => {
    if (!folderData) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    if (folderTypeCategories[folderData?.typeFolder] === 'public' && !isAdmin) {
      return false;
    }

    if (folderData.userCreateId === userId) {
      return true;
    }

    if (
      folderTypeCategories[folderData?.typeFolder] === 'private' &&
      !isAdmin
    ) {
      return true;
    }

    return false;
  }, [isAdmin, folderData, userId]);

  const allowEditAndDelete = useMemo(() => {
    if (!folderData) {
      return false;
    }

    if (folderData.id === defaultFolderId) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    return folderData.userCreateId === userId;
  }, [folderData, defaultFolderId, isAdmin, userId]);

  const allowMove = useMemo(() => {
    if (!folderData) {
      return false;
    }

    if (folderData.id === defaultFolderId) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    return folderData.userCreateId === userId;
  }, [folderData, defaultFolderId, isAdmin, userId]);

  return {
    allowCreate,
    allowEditAndDelete,
    allowMove,
  };
};
