import 'grapesjs/dist/css/grapes.min.css';
import grapesjs, {
  type Block,
  type Component,
  type DeviceProperties,
  type Editor,
  type EditorConfig,
  type I18nConfig,
} from 'grapesjs';
import blocksBasicPlugin, {
  type PluginOptions as PluginBlocksBasicOptions,
} from 'grapesjs-blocks-basic';
import presetWebpagePlugin, {
  type PluginOptions as PluginPresetWebpageOptions,
} from 'grapesjs-preset-webpage';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  getRouteFrom,
  useBreadcrumbs,
  useToast,
} from '@gbs-monorepo-packages/common';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { GOOGLE_FONTS_API_URL } from '../../constants/Env';
import { type ICustomFont } from '../../constants/Fonts';
import { mdScreens, smScreens } from '../../constants/grapes';
import { CREATE_TEMPLATE, EDIT_COURSE } from '../../constants/RoutePaths';
import { type IFontDTO } from '../../services/courses';
import Logger from '../../utils/logger';
import BulletPointPlugin from '../GrapesJS/Blocks/BulletPoint';
import ButtonLinkPlugin from '../GrapesJS/Blocks/ButtonLink';
import { ModalConfigLinks } from '../GrapesJS/Blocks/ButtonLink/ModalLinkSettings';
import CustomText from '../GrapesJS/Blocks/CustomText';
import { ModalAutobuildSettings } from '../GrapesJS/Blocks/CustomText/ModalAutobuildSettings';
import DesktopOnlyColumnPlugin from '../GrapesJS/Blocks/DesktopOnlyColumn';
import DividerPlugin from '../GrapesJS/Blocks/Divider';
import MobileOnlyColumnPlugin from '../GrapesJS/Blocks/MobileOnlyColumn';
import TableLayoutPlugin, {
  tableLayoutId,
} from '../GrapesJS/Blocks/TableLayout';
import ThreeColumnsResponsivePlugin from '../GrapesJS/Blocks/ThreeColumnsResponsive';
import TwoColumnsResponsivePlugin from '../GrapesJS/Blocks/TwoColumnsResponsive';
import TwoColumnsSideBarResponsivePlugin from '../GrapesJS/Blocks/TwoColumnsSideBarResponsive';
import {
  CustomCommandsPlugin,
  addFlexGap,
  addMaxMinSizes,
  addOverflow,
  addVerticalAlign,
} from '../GrapesJS/Customs';
import { AddToolbarButtons } from '../GrapesJS/Customs/AddToolbarButtons';
import CommandsInfoPlugin, {
  type ICommandsInfoPluginOptions,
} from '../GrapesJS/Customs/CommandsInfo';
import { PreventDefaultCommandError } from '../GrapesJS/Customs/Default';
import { DeleteOnlyComponentPlugin } from '../GrapesJS/Customs/DeleteOnlyComponent';
import { HandlerTablePlugin } from '../GrapesJS/Customs/HandleTables';
import { ModalTableLayoutPopover } from '../GrapesJS/Customs/ModalResponsiveTablePopover';
import {
  type IUploadModalComponentsPluginOptions,
  ModalUploadComponentsPlugin,
} from '../GrapesJS/Customs/ModalUploadImage';
import { ModalUploadImagePopover } from '../GrapesJS/Customs/ModalUploadImage/ModalUploadImagePopover';
import SearchComponentsPlugin, {
  type ISearchComponentsPluginOptions,
  handlerSearchComponents,
} from '../GrapesJS/Customs/SearchComponents';
import { SearchComponentsPopover } from '../GrapesJS/Customs/SearchComponents/SearchComponentsPopover';
import { SelectChildrenPlugin } from '../GrapesJS/Customs/SelectChildren';
import ToogleWrapperPlugin from '../GrapesJS/Customs/ToggleWrapper';
import { type ICommandInfo } from '../GrapesJS/interfaces';
import { SpotHandler } from '../GrapesJS/Spots';
import {
  Container,
  CustomCommandsInfoModal,
  EditorDiv,
  Loading,
  Overlay,
} from './styles';

export interface IWebBuilderProps {
  customConfig?: Partial<EditorConfig>;
  onEditorReady?: (editor: Editor) => void;
  onEditorDestroyed?: () => void;
  isDraggingOver?: boolean;
  fonts?: IFontDTO[];
  setDefaultFontsCount?: (count: number) => void;
}

const addTooltips = (editor: Editor) => {
  const panelManager = editor.Panels;

  [
    ['undo', 'Undo'],
    ['redo', 'Redo'],
    ['gjs-open-import-webpage', 'Import'],
    ['canvas-clear', 'Clear'],
  ].forEach((item) => {
    panelManager.getButton('options', item[0])?.set('attributes', {
      title: item[1],
    });
  });
};

export const WebBuilder = ({
  customConfig,
  onEditorReady,
  onEditorDestroyed,
  isDraggingOver = false,
  fonts = [],
  setDefaultFontsCount,
}: IWebBuilderProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const { companyId = '', courseId = '', templateId = '' } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clearBreadcrumbs } = useBreadcrumbs();

  const [isCommandsInfoModalOpen, setIsCommandsInfoModalOpen] = useState(false);
  const [commandsInfo, setCommandsInfo] = useState<ICommandInfo[]>([]);
  const [dropTargetTable, setDropTargetTable] = useState<Component>();
  const [blockStartEvent, setBlockStartEvent] = useState<Block | null>(null);

  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);

  const [isModalUploadPopoverOpen, setIsModalUploadPopoverOpen] =
    useState(false);
  const [isModalAutobuildSettingsOpen, setIsModalAutobuildSettingsOpen] =
    useState(false);
  const [isModalConfigLinkOpen, setModalConfigLinkOpen] = useState(false);
  const [isModalTabledPopoverOpen, setIsModalTablePopoverOpen] =
    useState(false);
  const [isAllowDropComponent, setIsAllowDropComponent] = useState(true);
  const [changeImage, setChangeImage] = useState(false);
  const [changeIdImage, setChangeIdImage] = useState<string>('');

  const buttonRef = useRef<HTMLDivElement>(null);

  const editorRef = useRef<Editor | null>(null);

  const { addToast } = useToast();

  const addCustomFonts = (editor: Editor) => {
    const styleManager = editor.StyleManager;
    const fontFamily = styleManager.getBuiltIn('font-family');

    // @ts-expect-error: the options is missing in PropertyProps
    fontFamily.options.length = 0;

    const defaultFonts: ICustomFont[] = [
      { label: 'Arial', id: `Arial, Helvetica, sans-serif` },
      { label: 'Arial Black', id: `"Arial Black", Gadget, sans-serif` },
      { label: 'Brush Script MT', id: `"Brush Script MT", sans-serif` },
      { label: 'Comic Sans MS', id: `"Comic Sans MS", cursive, sans-serif` },
      { label: 'Courier New', id: `"Courier New", Courier, monospace` },
      { label: 'Georgia', id: `Georgia, serif` },
      { label: 'Helvetica', id: `Helvetica, sans-serif` },
      { label: 'Impact', id: `Impact, Charcoal, sans-serif` },
      {
        label: 'Lucida Sans Unicode',
        id: `"Lucida Sans Unicode", "Lucida Grande", sans-serif`,
      },
      { label: 'Tahoma', id: `Tahoma, Geneva, sans-serif` },
      { label: 'Times New Roman', id: `"Times New Roman", Times, serif` },
      { label: 'Trebuchet MS', id: `"Trebuchet MS", Helvetica, sans-serif` },
      { label: 'Verdana', id: `Verdana, Geneva, sans-serif` },
    ];

    defaultFonts.forEach((font: ICustomFont) => {
      // @ts-expect-error: the options is missing in PropertyProps
      fontFamily.options.push({
        label: font.label,
        id: `${font.id}`,
      } as ICustomFont);
    });

    fonts.forEach((font: IFontDTO) => {
      // @ts-expect-error: the options is missing in PropertyProps
      fontFamily.options.push({
        label: font.family,
        id: `"${font.family}", ${font.category}`,
      } as ICustomFont);
    });

    if (fontFamily !== undefined) {
      styleManager.addBuiltIn('font-family', fontFamily);
    }
    styleManager.render();
  };

  useEffect(() => {
    const setupBuilder = () => {
      const blocksBasicOptions: PluginBlocksBasicOptions = {
        category: 'Basic',
        blocks: [
          'column1',
          'column2',
          'column3',
          'column3-7',
          'text',
          'link',
          'video',
        ],
      };

      const presetWebpageOptions: PluginPresetWebpageOptions = {
        modalImportTitle: 'Edit the code',
        modalImportButton: 'Edit',
        modalImportContent: (editor: Editor) => editor.getHtml(),
        importViewerOptions: {},
        textCleanCanvas: 'Are you sure you want to clear the canvas?',
        showStylesOnChange: true,
        useCustomTheme: true,
      };

      const commandsInfoOptions: ICommandsInfoPluginOptions = {
        handlerOpenCommandsInfo: handleOpenCommandsInfo,
        setCommandsInfo,
      };

      const searchComponentsOptions: ISearchComponentsPluginOptions = {
        buttonRef,
        handlerOpenSearchComponents: handleOpenSearchPopover,
      };

      const modalUploadComponentsOptions: IUploadModalComponentsPluginOptions =
        {
          handlerOpenUploadComponents: handleOpenModalUploadPopover,
          handlerCloseUploadComponents: handleCloseModalUploadNotAllowPopover,
        };

      const devicesConfig: DeviceProperties[] = [
        {
          id: 'desktop',
          name: 'Desktop',
          width: '',
        },
        {
          id: 'tablet',
          name: 'Tablet',
          width: mdScreens.width,
          widthMedia: mdScreens.mediaWidth,
        },
        {
          id: 'mobilePortrait',
          name: 'Mobile portrait',
          width: smScreens.width,
          widthMedia: smScreens.mediaWidth,
          height: smScreens.height,
        },
      ];

      const fontsURLs: string[] = [];

      fonts.forEach((font: IFontDTO) => {
        fontsURLs.push(`${GOOGLE_FONTS_API_URL}?family=${font.family}`);
      });

      const i18nConfig: I18nConfig = {
        messagesAdd: {
          en: {
            assetManager: {
              addButton: 'Add a URL image',
            },
          },
        },
      };

      const newEditor = grapesjs.init({
        container: '#gjs',
        height: '100%',
        width: '100%',
        plugins: [
          ThreeColumnsResponsivePlugin,
          TwoColumnsResponsivePlugin,
          TwoColumnsSideBarResponsivePlugin,
          MobileOnlyColumnPlugin,
          DesktopOnlyColumnPlugin,
          (editor) => {
            ModalUploadComponentsPlugin(editor, modalUploadComponentsOptions);
          },
          (editor) => {
            blocksBasicPlugin(editor, blocksBasicOptions);
          },
          (editor) => {
            presetWebpagePlugin(editor, presetWebpageOptions);
          },
          CustomCommandsPlugin,
          (editor) => {
            SearchComponentsPlugin(editor, searchComponentsOptions);
          },
          ButtonLinkPlugin,
          TableLayoutPlugin,
          BulletPointPlugin,
          ToogleWrapperPlugin,
          DividerPlugin,
          SelectChildrenPlugin,
          PreventDefaultCommandError,
          DeleteOnlyComponentPlugin,
          AddToolbarButtons,
          CustomText,
          HandlerTablePlugin,
        ],
        storageManager: false,
        deviceManager: {
          devices: devicesConfig,
        },
        layerManager: {
          sortable: true,
          hidable: false,
        },
        i18n: i18nConfig,
        canvas: {
          styles: fontsURLs,
          customSpots: {},
        },
        ...customConfig,
      });

      addTooltips(newEditor);
      addVerticalAlign(newEditor);
      addMaxMinSizes(newEditor);
      addOverflow(newEditor);
      addFlexGap(newEditor);
      newEditor.SelectorManager.setComponentFirst(true);
      CommandsInfoPlugin(newEditor, commandsInfoOptions);
      editorRef.current = newEditor;

      newEditor.onReady(() => {
        onEditorReady?.(newEditor);
        setLoading(false);
        setDefaultFontsCount?.(
          // @ts-expect-error: the options is missing in PropertyProps
          newEditor.StyleManager.getBuiltIn('font-family')?.options.length
        );
        addCustomFonts(newEditor);
      });

      newEditor.on('block:drag:stop', (dropTarget: Component, block: Block) => {
        if (
          block.id === tableLayoutId ||
          (block.id === 'modal-upload' && dropTarget !== null)
        ) {
          setDropTargetTable(dropTarget);
          setBlockStartEvent(block);
        }
      });

      newEditor.on('autobuild:click', (_component: Component) => {
        setIsModalAutobuildSettingsOpen(true);
      });

      newEditor.on('configlink:click', (_component: Component) => {
        handleOpenConfigLink(newEditor);
      });
    };
    setLoading(true);
    setupBuilder();

    return () => {
      const editor = editorRef.current;
      if (editor !== null) {
        onEditorDestroyed?.();
        editor.destroy();
        Logger.notice('WebBuilder destroyed');
      } else {
        Logger.debug('WebBuilder was not destroyed');
      }
    };
  }, []);

  const handleOpenConfigLink = useCallback((_editor: Editor) => {
    setModalConfigLinkOpen(true);
  }, []);

  const handleOpenCommandsInfo = useCallback((_editor: Editor) => {
    setIsCommandsInfoModalOpen(true);
  }, []);

  const handleCloseCommandsInfo = useCallback(() => {
    setIsCommandsInfoModalOpen(false);
  }, []);

  const handleOpenSearchPopover = useCallback((_editor: Editor) => {
    setIsSearchPopoverOpen(true);
  }, []);
  const handleCloseSearchPopover = useCallback(() => {
    setIsSearchPopoverOpen(false);
  }, []);

  const handleOpenModalUploadPopover = useCallback(
    (_editor: Editor, changeImage = false, idImage = '') => {
      if (isAllowDropComponent) {
        setChangeImage(changeImage);
        setChangeIdImage(idImage);
        setIsModalUploadPopoverOpen(true);
      }
      setIsAllowDropComponent(true);
    },
    []
  );

  useEffect(() => {
    if (
      blockStartEvent?.id === 'modal-upload' &&
      dropTargetTable &&
      editorRef.current !== null
    ) {
      handleOpenModalUploadPopover(editorRef?.current);
    }
  }, [handleOpenModalUploadPopover, dropTargetTable, blockStartEvent?.id]);

  useEffect(() => {
    if (!isAllowDropComponent) {
      setIsModalUploadPopoverOpen(false);
      setDropTargetTable(undefined);
    }
    setIsAllowDropComponent(true);
  }, [isAllowDropComponent]);

  const handleOpenModalTablePopover = useCallback(() => {
    setIsModalTablePopoverOpen(true);
  }, []);

  useEffect(() => {
    if (
      blockStartEvent?.id === tableLayoutId &&
      dropTargetTable &&
      editorRef.current !== null
    ) {
      handleOpenModalTablePopover();
    }
  }, [handleOpenModalTablePopover, dropTargetTable, blockStartEvent?.id]);

  const handleCloseModalTablePopover = useCallback(() => {
    setIsModalTablePopoverOpen(false);
    setDropTargetTable(undefined);
  }, []);

  const handleCloseModalUploadPopover = useCallback(() => {
    setIsModalUploadPopoverOpen(false);
    setDropTargetTable(undefined);
  }, []);

  const handleCloseModalAutobuildSettings = useCallback(() => {
    setIsModalAutobuildSettingsOpen(false);
  }, []);

  const handleCloseModalConfigLink = useCallback(() => {
    clearBreadcrumbs();
    let route = '';
    if (pathname.includes('folder')) {
      if (courseId) {
        route = generatePath(getRouteFrom(EDIT_COURSE), {
          companyId: companyId.toString(),
          courseId: courseId.toString(),
        });
      } else {
        route = generatePath(getRouteFrom(CREATE_TEMPLATE), {
          companyId: companyId.toString(),
          templateId: templateId.toString(),
        });
      }
      navigate(route);
    }
    setModalConfigLinkOpen(false);
  }, [clearBreadcrumbs, companyId, courseId, navigate, pathname, templateId]);

  const handleCloseModalUploadNotAllowPopover = useCallback(() => {
    setIsAllowDropComponent(false);
  }, []);

  const handleAcceptSearchPopover = useCallback(
    (newSearch: string, isOnlyInsideSelection: boolean) => {
      const editor = editorRef.current;
      if (editor === null) return;

      try {
        const foundComponents = handlerSearchComponents(editor, {
          newSearch,
          isOnlyInsideSelection,
        });

        if (foundComponents.length === 0) {
          addToast({
            title: `No components found`,
            styleType: 'error',
            dataCy: 'search-components-not-found-error-toast',
          });
        } else {
          addToast({
            title: `${foundComponents.length} component${
              foundComponents.length > 1 ? 's' : ''
            } found`,
            styleType: 'success',
            dataCy: 'search-components-success-toast',
          });
        }
      } catch (err) {
        if (err instanceof Error) {
          addToast({
            title: 'Something went wrong',
            description: err.message,
            styleType: 'error',
            dataCy: 'search-components-error-toast',
          });
        }
      }

      setIsSearchPopoverOpen(false);
    },
    []
  );

  return (
    <>
      {isDraggingOver && <Overlay data-cy="web-builder-overlay" />}
      <EditorDiv isLoading={loading} id="gjs" data-cy="web-builder" />
      {loading && (
        <Container data-cy="web-builder-loading-container">
          <Loading
            data-cy="web-builder-loading"
            isLoading={loading}
            src={LoadingSpinnerAnimated}
          />
        </Container>
      )}

      {createPortal(
        <SpotHandler editor={editorRef.current ?? undefined} />,
        editorRef.current?.Canvas.getSpotsEl() ?? document.body
      )}

      <CustomCommandsInfoModal
        commandsInfo={commandsInfo}
        onClose={handleCloseCommandsInfo}
        open={isCommandsInfoModalOpen}
      />

      <SearchComponentsPopover
        onAccept={handleAcceptSearchPopover}
        onDecline={handleCloseSearchPopover}
        open={isSearchPopoverOpen}
        trigger={<div ref={buttonRef} />}
        zIndex={10}
      />

      {editorRef.current &&
        dropTargetTable &&
        blockStartEvent?.id === tableLayoutId && (
          <ModalTableLayoutPopover
            draggableArea={dropTargetTable}
            onClose={handleCloseModalTablePopover}
            open={isModalTabledPopoverOpen}
          />
        )}

      {editorRef.current && (
        <ModalUploadImagePopover
          companyId={Number(companyId)}
          pageId={courseId ? Number(courseId) : Number(templateId)}
          editor={editorRef.current}
          open={isModalUploadPopoverOpen}
          onClose={handleCloseModalUploadPopover}
          draggableArea={dropTargetTable}
          screen={courseId ? 'course' : 'template'}
          multipleChoose={!changeImage}
          idImage={changeIdImage}
        />
      )}

      {editorRef.current && (
        <ModalAutobuildSettings
          editor={editorRef.current}
          open={isModalAutobuildSettingsOpen}
          onClose={handleCloseModalAutobuildSettings}
        />
      )}

      {editorRef.current && (
        <ModalConfigLinks
          editor={editorRef.current}
          open={isModalConfigLinkOpen}
          onClose={handleCloseModalConfigLink}
        />
      )}
    </>
  );
};
