import debounce from 'lodash.debounce';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  BreadcrumbsComponent,
  DEBOUNCE_TIME,
  DefaultDescription,
  ETypeFolder,
  FormModal,
  type IApiThrowsError,
  type IFolderDTO,
  INIT_PAGE,
  LIMIT_PAGE,
  Logger,
  MainContainer,
  ManagerRoles,
  PageContent,
  PageHeader,
  Roles,
  SearchBar,
  getRouteFrom,
  useBreadcrumbs,
  useCanDocuments,
  useToast,
} from '@gbs-monorepo-packages/common';

import { ListFolder } from '../../components/ListFolder';
import { FOLDER } from '../../constants/RoutePaths';
import { useCompanies } from '../../hooks/useCompanies';
import { useFolder } from '../../hooks/useFolder';
import {
  type ICreateFolderProps,
  type IPaginationFolderDTO,
  type IUpdateFolderProps,
} from '../../services/folders';
import { AddFolderModal } from './components/AddFolder';
import { EditFolderModal } from './components/EditFolder';
import { SearchContainer } from './styles';

const DocumentHeaderColumns = [
  {
    id: 'name',
    name: 'Description',
    textAlign: 'start',
  },
  {
    id: 'updatedAt',
    name: 'Last Upload',
    textAlign: 'end',
  },
  {
    id: 'options',
    name: '',
    textAlign: 'center',
  },
];

export const FoldersDocuments = (): JSX.Element => {
  const { typeFolder = '', companyId = '' } = useParams();
  const { selectedCompany } = useCompanies();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const folderRoute = getRouteFrom(FOLDER);
  const { user } = useAuth();

  const { breadcrumbs, addBreadcrumb, removeBreadcrumb, clearBreadcrumbs } =
    useBreadcrumbs();

  const {
    loadingFolders,
    getListFolderByClient,
    folders,
    setFolders,
    paginationMeta,
    handleSetSortOrder,
    sortOrder,
    createFolder,
    updateFolder,
    deleteFolder,
  } = useFolder();

  const constTypeFolder =
    typeFolder === 'private' ? ETypeFolder.PRIVATE : ETypeFolder.PUBLIC;
  const TitlePageText =
    typeFolder === 'private'
      ? 'Private GBS Documents'
      : 'Public Employee Documents';

  const [loadingActions, setLoadingActions] = useState(false);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);

  const { addToast } = useToast();

  const [folderToEdit, setFolderEdit] = useState<IFolderDTO | null>(null);
  const [folderDelete, setFolderDelete] = useState<IFolderDTO | null>(null);
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isModalDeleteOpen = !!folderDelete;
  const userIsAdmin = useMemo(() => {
    return (
      user?.roles.includes(Roles.ADMIN) === true ||
      ManagerRoles.some((role) => user?.roles.includes(role))
    );
  }, [user]);

  const { allowCreate } = useCanDocuments({
    userId: user?.id,
    isAdmin: userIsAdmin,
    folderData: {
      typeFolder: constTypeFolder,
    },
  });

  const defaultFolderId = useMemo(
    () => selectedCompany?.defaultLmsFolder.id,
    [selectedCompany]
  );

  const toRouteSubFolder = useCallback(
    (folderId: number | string) =>
      generatePath(folderRoute, {
        companyId,
        typeFolder,
        folderId,
      }),
    [companyId, folderRoute, typeFolder]
  );

  const handleRowClick = useCallback(
    (item: IFolderDTO) => {
      const route = toRouteSubFolder(item.id);
      addBreadcrumb({
        name: item.name,
        url: route,
      });
      navigate(route, { state: { from: pathname } });
    },
    [addBreadcrumb, navigate, pathname]
  );

  const handleSetIsDropdownOpen = useCallback((isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  }, []);

  const handleSelectFolderEdit = useCallback(
    (item: IFolderDTO) => {
      setFolderEdit(item);
    },
    [setFolderEdit]
  );

  const handleSelectFolderDelete = useCallback(
    (item: IFolderDTO) => {
      setFolderDelete(item);
    },
    [setFolderDelete]
  );

  const getFolders = useCallback(
    async (companyId: string, page: number, limit: number, filter?: string) => {
      await getListFolderByClient({
        clientId: companyId,
        page,
        limit,
        filter: filter ?? '',
        search: String(constTypeFolder),
        sort: JSON.stringify(sortOrder),
      })
        .then((response?: IPaginationFolderDTO) => {
          if (response) {
            if (
              defaultFolderId != null &&
              constTypeFolder === ETypeFolder.PUBLIC &&
              breadcrumbs?.length === 0
            ) {
              const defaultFolder = response.data.find(
                (folder) => folder.id === defaultFolderId
              );
              if (defaultFolder) {
                addBreadcrumb({
                  name: defaultFolder.name,
                  url: toRouteSubFolder(defaultFolder.id),
                });
                navigate(toRouteSubFolder(defaultFolder.id), {
                  state: { from: pathname },
                });
              }
            }
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
          addToast({
            title: 'Error on load folders',
            description: DefaultDescription,
            styleType: 'error',
            dataCy: 'get-folder-error-toast',
            duration: 3000,
          });
        });
    },
    [companyId, constTypeFolder, sortOrder]
  );

  useEffect(() => {
    if (breadcrumbs?.length !== 0) {
      void getFolders(companyId ?? 0, INIT_PAGE, LIMIT_PAGE, search);
    }
  }, [typeFolder, companyId]);

  useEffect(() => {
    clearBreadcrumbs();
  }, [typeFolder, companyId]);

  useEffect(() => {
    if (breadcrumbs?.length === 0) {
      void getFolders(companyId ?? 0, INIT_PAGE, LIMIT_PAGE, search);
      addBreadcrumb({
        name: TitlePageText,
        url: pathname,
      });
    }
  }, [breadcrumbs]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setFolders([]);
        searchFolder(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, DEBOUNCE_TIME);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    const debouncedGetFolders = debounce(() => {
      if (sortOrder) {
        void getFolders(companyId ?? 0, INIT_PAGE, LIMIT_PAGE, search);
      }
    }, DEBOUNCE_TIME);

    debouncedGetFolders();

    return () => {
      debouncedGetFolders.cancel();
    };
  }, [companyId, getFolders, search, sortOrder]);

  const searchFolder = (searchByButton = false) => {
    if (!loadingFolders || searchByButton) {
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      const clientId = companyId ?? 0;
      getListFolderByClient({
        clientId: String(clientId),
        page: pageAux + 1,
        limit: LIMIT_PAGE,
        filter: search ?? '',
        search: String(constTypeFolder),
        sort: JSON.stringify(sortOrder),
      }).catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on load folders',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'get-folder-error-toast',
          duration: 3000,
        });
      });
    }
  };

  const addFolder = useCallback(
    async (folder: ICreateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);

        await createFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder created',
              styleType: 'success',
              dataCy: 'create-folder-success-toast',
              duration: 3000,
            });
            setOpenAddFolderModal(false);
            void getFolders(
              companyId ?? 0,
              INIT_PAGE,
              LIMIT_PAGE,
              lastSearch.current
            );
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on creating folder',
              description:
                error.response?.data.error &&
                error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'create-folder-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [addToast, getFolders, loadingActions]
  );

  const editFolder = useCallback(
    async (folder: IUpdateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);

        await updateFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder updated',
              styleType: 'success',
              dataCy: 'updated-folder-success-toast',
              duration: 3000,
            });
            void getFolders(
              companyId ?? 0,
              INIT_PAGE,
              LIMIT_PAGE,
              lastSearch.current
            );
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on updated folder',
              description:
                error.response?.data.error &&
                error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'updated-folder-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [addToast, getFolders, loadingActions]
  );

  const removeFolder = useCallback(() => {
    if (!folderDelete) {
      return;
    }

    if (!loadingActions) {
      setLoadingActions(true);

      deleteFolder(folderDelete.id)
        .then(() => {
          addToast({
            title: 'Folder deleted',
            styleType: 'success',
            dataCy: 'delete-folder-success-toast',
            duration: 3000,
          });
          void getFolders(
            companyId ?? 0,
            INIT_PAGE,
            LIMIT_PAGE,
            lastSearch.current
          );
        })
        .catch(() => {
          addToast({
            title: 'Error on deleting folder',
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'delete-folder-error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setFolderDelete(null);
          setLoadingActions(false);
        });
    }
  }, [addToast, folderDelete, getFolders, loadingActions]);

  const handleDecline = useCallback(() => {
    setFolderEdit(null);
  }, []);

  const handleDeclineDelete = useCallback(() => {
    setFolderDelete(null);
  }, []);

  const removeBreadcrumbPage = useCallback(
    (index: number) => {
      removeBreadcrumb(index);
    },
    [removeBreadcrumb]
  );

  const hasRequestInLoading = useMemo(() => {
    return loadingFolders || loadingActions;
  }, [loadingFolders, loadingActions]);

  return (
    <MainContainer data-cy="document-container">
      <PageHeader
        title={TitlePageText}
        buttonText={allowCreate ? 'Add Folder' : ''}
        onClick={() => {
          setOpenAddFolderModal(true);
        }}
        disabledButton={hasRequestInLoading}
      />
      <PageContent>
        <SearchContainer>
          <SearchBar
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
            onClearInput={() => {
              setSearch('');
            }}
            search={search}
            maxLength={50}
            placeholder="Search folder"
            loading={loadingFolders}
            id="search-folder"
          />
        </SearchContainer>
        <BreadcrumbsComponent
          links={breadcrumbs}
          onClick={removeBreadcrumbPage}
        />
        <ListFolder
          loading={loadingFolders}
          folders={folders}
          paginationMeta={paginationMeta}
          searchFolder={searchFolder}
          setSortOrder={handleSetSortOrder}
          sortOrder={sortOrder}
          headerColumns={DocumentHeaderColumns}
          handleSelectEdit={handleSelectFolderEdit}
          handleSelectDelete={handleSelectFolderDelete}
          handleFolderClick={handleRowClick}
          handleSetIsDropdownOpen={handleSetIsDropdownOpen}
          disabledActions={false}
        />
      </PageContent>
      <AddFolderModal
        typeFolder={constTypeFolder}
        onAccept={addFolder}
        onDecline={() => {
          setOpenAddFolderModal(false);
        }}
        open={!isDropdownOpen && openAddFolderModal}
        loading={loadingActions}
        zIndex={10}
      />

      {folderToEdit && (
        <EditFolderModal
          folder={folderToEdit}
          onAccept={editFolder}
          onDecline={handleDecline}
          open={!isDropdownOpen && folderToEdit !== null}
          loading={loadingActions}
          zIndex={10}
        />
      )}

      {folderDelete && (
        <FormModal
          dataCy="delete-folder-dialog"
          acceptText="Confirm"
          declineText="Cancel"
          open={!isDropdownOpen && isModalDeleteOpen}
          loading={loadingActions}
          mainText={`Are you sure you want to delete ${folderDelete.name} ?`}
          onAccept={removeFolder}
          onDecline={handleDeclineDelete}
          onOpenChange={handleDeclineDelete}
          zIndex={10}
        />
      )}
    </MainContainer>
  );
};
