import debounce from 'lodash.debounce';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  BreadcrumbsComponent,
  DEBOUNCE_TIME,
  DefaultDescription,
  ETypeFolder,
  type IApiThrowsError,
  type IFolderDTO,
  INIT_PAGE,
  type ISort,
  LIMIT_PAGE,
  Logger,
  SearchBar,
  getRouteFrom,
  useBreadcrumbs,
  useToast,
} from '@gbs-monorepo-packages/common';

import {
  CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER_ITEMS,
  EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER_ITEMS,
} from '../../../../../../../../constants/RoutePaths';
import { useFolder } from '../../../../../../../../hooks/useFolder';
import { ListFolder } from '../../../../../../../ListFolder';
import { Content, SearchContainer } from './styles';

const DocumentHeaderColumns = [
  {
    id: 'name',
    name: 'Description',
    textAlign: 'start',
  },
  {
    id: 'updatedAt',
    name: 'Last Upload',
    textAlign: 'end',
  },
  {
    id: 'options',
    name: '',
    textAlign: 'center',
  },
];

export const Folders = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    loadingFolders,
    getListFolderByClient,
    folders,
    setFolders,
    paginationMeta,
    handleSetSortOrder,
    sortOrder,
  } = useFolder();
  const { addToast } = useToast();
  const { companyId = '', courseId = '', templateId = '' } = useParams();
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [_, setIsDropdownOpen] = useState(false);
  const { pathname } = useLocation();

  const { breadcrumbs, addBreadcrumb, removeBreadcrumb, clearBreadcrumbs } =
    useBreadcrumbs();

  const editCourseFolderItemRoutes = getRouteFrom(
    EDIT_COURSE_MODAL_BUTTON_LINK_FOLDER_ITEMS
  );
  const editTemplateFolderRoute = getRouteFrom(
    CREATE_TEMPLATE_MODAL_BUTTON_LINK_FOLDER_ITEMS
  );

  const getFolders = useCallback(
    async (
      companyId: string,
      page: number,
      limit: number,
      filter?: string,
      sort?: ISort | null
    ) => {
      await getListFolderByClient({
        clientId: companyId,
        page,
        limit,
        filter: filter ?? '',
        search: String(ETypeFolder.PUBLIC),
        sort: JSON.stringify(sort) ?? null,
      }).catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on load folders',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'get-folder-error-toast',
          duration: 3000,
        });
      });
    },
    [addToast, getListFolderByClient]
  );

  useEffect(() => {
    if (breadcrumbs?.length !== 0) {
      clearBreadcrumbs();
    }
  }, []);

  useEffect(() => {
    if (breadcrumbs?.length === 0) {
      addBreadcrumb({
        name: 'Public Employee Documents',
        url: pathname,
      });
    }
  }, [breadcrumbs]);

  useEffect(() => {
    void getFolders(companyId, INIT_PAGE, LIMIT_PAGE, search, sortOrder);
  }, []);

  const handleSetIsDropdownOpen = useCallback((isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setFolders([]);
        searchFolder(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, DEBOUNCE_TIME);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    const debouncedGetFolders = debounce(() => {
      if (sortOrder) {
        void getFolders(companyId, INIT_PAGE, LIMIT_PAGE, search, sortOrder);
      }
    }, DEBOUNCE_TIME);

    debouncedGetFolders();

    return () => {
      debouncedGetFolders.cancel();
    };
  }, [getFolders, search, sortOrder]);

  const searchFolder = (searchByButton = false) => {
    if (!loadingFolders || searchByButton) {
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      void getFolders(companyId, pageAux + 1, LIMIT_PAGE, search, sortOrder);
    }
  };

  const toRouteSubFolderEditCourse = useCallback(
    (folderId: number | string) =>
      generatePath(editCourseFolderItemRoutes, {
        folderId: folderId.toString(),
        companyId,
        courseId,
      }),
    [companyId, courseId, editCourseFolderItemRoutes]
  );

  const toRouteSubFolderEditTemplate = useCallback(
    (folderId: number | string) =>
      generatePath(editTemplateFolderRoute, {
        folderId: folderId.toString(),
        companyId,
        templateId,
      }),
    [companyId, templateId, editTemplateFolderRoute]
  );

  const handleRowClick = useCallback(
    (item: IFolderDTO) => {
      let route = '';
      if (courseId) {
        route = toRouteSubFolderEditCourse(item.id);
      } else {
        route = toRouteSubFolderEditTemplate(item.id);
      }
      addBreadcrumb({
        name: item.name,
        url: route,
      });
      navigate(route, { state: { from: pathname } });
    },
    [
      addBreadcrumb,
      courseId,
      navigate,
      pathname,
      toRouteSubFolderEditCourse,
      toRouteSubFolderEditTemplate,
    ]
  );

  const removeBreadcrumbPage = useCallback(
    (index: number) => {
      removeBreadcrumb(index);
    },
    [removeBreadcrumb]
  );

  return (
    <Content>
      <SearchContainer>
        <SearchBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
          }}
          onClearInput={() => {
            setSearch('');
          }}
          search={search}
          maxLength={40}
          placeholder="Search folder"
          loading={loadingFolders}
          id="search-folder"
        />
      </SearchContainer>
      <BreadcrumbsComponent
        links={breadcrumbs}
        onClick={removeBreadcrumbPage}
      />
      <ListFolder
        loading={loadingFolders}
        folders={folders}
        paginationMeta={paginationMeta}
        searchFolder={searchFolder}
        setSortOrder={handleSetSortOrder}
        sortOrder={sortOrder}
        headerColumns={DocumentHeaderColumns}
        handleFolderClick={handleRowClick}
        handleSetIsDropdownOpen={handleSetIsDropdownOpen}
        disabledActions
      />
    </Content>
  );
};
