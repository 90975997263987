import { useMemo } from 'react';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  DocumentCard,
  FolderCard,
  type IColumn,
  type IFolderDTO,
  type IPaginationMetaProps,
  type ISort,
  ListLoading,
  ManagerRoles,
  Roles,
} from '@gbs-monorepo-packages/common';

import { useDocument } from '../../hooks/useDocuments';
import { type IDocumentDTO } from '../../services/documentsFolder';
import { TableCustom } from './styles';

interface IListFolderProps {
  folders: IFolderDTO[];
  documents: IDocumentDTO[];
  loading: boolean;
  paginationMeta: IPaginationMetaProps | null;
  search: () => void;
  setSortOrder: (value: ISort | null) => void;
  sortOrder: ISort | null;
  headerColumns: Array<{
    id: string;
    name: string;
    textAlign: string;
  }>;
  disabledActions: boolean;

  handleFolderClick: (item: IFolderDTO) => void;
  handleSelectFolderEdit?: (item: IFolderDTO) => void;
  handleSelectFolderDelete?: (item: IFolderDTO) => void;

  handleSetIsDropdownOpen?: (value: boolean) => void;

  handleOnClipboardCopy?: (item: IDocumentDTO) => void;
  handleSelectDocumentDelete?: (item: IDocumentDTO) => void;
  handleSelectDocument: (item: IDocumentDTO) => void;
  handleOpenPreviewModal?: (value: boolean) => void;
  handleDocumentStatusChange?: (id: number, status: string) => void;
}

export const ListDocument = ({
  folders,
  documents,
  loading,
  paginationMeta,
  search,
  setSortOrder,
  sortOrder,
  headerColumns,
  disabledActions = false,
  handleFolderClick,
  handleSelectFolderEdit,
  handleSelectFolderDelete,
  handleSetIsDropdownOpen,
  handleOnClipboardCopy,
  handleSelectDocumentDelete,
  handleSelectDocument,
  handleOpenPreviewModal,
}: IListFolderProps): JSX.Element => {
  const { user } = useAuth();
  const { folder: selectedFolder } = useDocument();
  const isAdmin = useMemo(() => {
    return (
      user?.roles.includes(Roles.ADMIN) === true ||
      ManagerRoles.some((role) => user?.roles.includes(role))
    );
  }, [user]);

  return (
    <>
      {loading ? (
        <ListLoading />
      ) : (
        <TableCustom
          columns={headerColumns}
          dataLength={documents.length + folders.length}
          next={search}
          loading={loading}
          hasMore={
            !!(
              paginationMeta?.total != null &&
              documents.length < paginationMeta?.total
            ) &&
            !!(paginationMeta?.total && folders.length < paginationMeta?.total)
          }
          noItensMessage="No items found"
          onClickHeader={(column: IColumn) => {
            setSortOrder({
              name: column.id,
              direction: sortOrder?.direction === 'ASC' ? 'DESC' : 'ASC',
            });
          }}
          columnSorted={sortOrder}
        >
          {selectedFolder &&
            folders.map((folder) => (
              <FolderCard
                key={folder.id}
                folder={folder}
                handleRowClick={() => {
                  handleFolderClick(folder);
                }}
                handleSelectDelete={handleSelectFolderDelete}
                handleSelectEdit={handleSelectFolderEdit}
                handleSetIsDropdownOpen={handleSetIsDropdownOpen}
                isAdmin={isAdmin}
                screen="documents"
                userId={user?.id ?? 0}
                disabledActions={disabledActions}
              />
            ))}

          {selectedFolder &&
            documents.map((item) => (
              <DocumentCard
                key={item.id}
                document={item}
                folder={selectedFolder}
                isAdmin={user?.roles.includes('ROLE_ADMIN') === true}
                userId={user?.id ?? 0}
                handleOnClipboardCopy={handleOnClipboardCopy}
                handleOpenPreviewModal={handleOpenPreviewModal}
                handleSelectDelete={handleSelectDocumentDelete}
                handleSelectDocument={handleSelectDocument}
                handleSetIsDropdownOpen={handleSetIsDropdownOpen}
              />
            ))}
        </TableCustom>
      )}
    </>
  );
};
