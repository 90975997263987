import {
  BaseDropdown,
  BaseDropdownItem,
  TableButtonActionsCell,
  TableCell,
  TableImageCell,
  TableRow,
} from '../../../components';
import {
  ETypeFolder,
  documentStatus,
  folderTypeCategories,
} from '../../../constants';
import { useCanDocuments } from '../../../hooks';
import { type IFolderDTO } from '../../../services';
import { type IDocumentDTO } from '../../../services/documents';
import { Logger, dateFormatFromJSON } from '../../../utils';
import {
  ButtonAction,
  DropdownButtonContainer,
  FileIcon,
  TableCellCustom,
} from './styles';

interface IFolderBase
  extends Pick<IFolderDTO, 'accountId' | 'id' | 'typeFolder'> {}

interface IDocumentCardProps<
  TDocument extends IDocumentDTO,
  TFolder extends IFolderBase
> {
  document: TDocument;
  folder: TFolder;
  isAdmin: boolean;
  userId: number;
  handleSelectDocument: (item: TDocument) => void;
  handleOnClipboardCopy?: (item: TDocument) => void;
  handleSelectDelete?: (item: TDocument) => void;
  handleOpenPreviewModal?: (value: boolean) => void;
  handleSetIsDropdownOpen?: (value: boolean) => void;
  handleDocumentStatusChange?: (id: number, status: string) => void;
}

export const DocumentCard = <
  TDocument extends IDocumentDTO,
  TFolder extends IFolderBase
>({
  document,
  folder,
  isAdmin,
  userId,
  handleOnClipboardCopy,
  handleSelectDelete,
  handleSelectDocument,
  handleOpenPreviewModal,
  handleSetIsDropdownOpen,

  handleDocumentStatusChange,
}: IDocumentCardProps<TDocument, TFolder>): JSX.Element => {
  const { allowEditAndDelete } = useCanDocuments({
    userId,
    isAdmin,
    folderData: {
      id: folder?.id,
      userCreateId: folder?.accountId,
      typeFolder: folder?.typeFolder ?? ETypeFolder.PRIVATE,
    },
  });

  return (
    <TableRow
      key={`${document.id} - ${
        document.name ?? document.originalName ?? 'unknown'
      }`}
      onClick={(e) => {
        e.stopPropagation();
        handleOpenPreviewModal?.(true);
        handleSelectDocument(document);
      }}
    >
      {folder?.typeFolder === ETypeFolder.RENEWAL && (
        <DocumentCardRenewal
          document={document}
          handleDocumentStatusChange={handleDocumentStatusChange}
        />
      )}
      {folder?.typeFolder !== ETypeFolder.RENEWAL && (
        <DocumentCardDefault document={document} isAdmin={isAdmin} />
      )}

      <TableCellCustom
        text={dateFormatFromJSON(document.createdAt, 'MM-dd-yyyy')}
        textAlign="end"
      />
      {allowEditAndDelete ? (
        <DropdownButtonContainer
          data-cy="dropdown-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <BaseDropdown
            dataCy={'dropdown-menu-button'}
            onOpenChange={(isOpen) => {
              handleSetIsDropdownOpen?.(isOpen);
              Logger.debug('isOpen', isOpen);
            }}
          >
            {folderTypeCategories[folder.typeFolder] === 'public' && (
              <BaseDropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClipboardCopy?.(document);
                }}
                data-cy={`documents-copyLink-${document.id}`}
              >
                Copy Link
              </BaseDropdownItem>
            )}
            <BaseDropdownItem
              data-cy={'edit-item'}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectDocument(document);
                handleOpenPreviewModal?.(true);
              }}
            >
              Edit
            </BaseDropdownItem>

            <BaseDropdownItem
              data-cy={'delete-item'}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectDelete?.(document);
              }}
            >
              Delete
            </BaseDropdownItem>
            {folderTypeCategories[folder.typeFolder] === 'public' && (
              <BaseDropdownItem
                onClick={() =>
                  window.open(`${document.path}`, '_blank')?.focus()
                }
                data-cy={`documents-download-${document.id}`}
              >
                Download
              </BaseDropdownItem>
            )}
          </BaseDropdown>
        </DropdownButtonContainer>
      ) : (
        <div></div>
      )}
    </TableRow>
  );
};

interface IDocumentCardDefaultProps<TDocument extends IDocumentDTO> {
  document: TDocument;
  isAdmin: boolean;
}

const DocumentCardDefault = <TDocument extends IDocumentDTO>({
  document,
  isAdmin,
}: IDocumentCardDefaultProps<TDocument>): JSX.Element => {
  return (
    <>
      <TableImageCell
        text={
          document.originalName
            ? document.originalName
            : document.name
            ? `${document.name}.${document?.extension ?? ''}`
            : '-'
        }
        subtitle=""
        dataCy="document-card"
        type="icon"
      >
        <FileIcon />
      </TableImageCell>
      <TableCellCustom
        text={
          document.name ? `${document.name}.${document?.extension ?? ''}` : '-'
        }
        textAlign="start"
      />
      {isAdmin && (
        <TableCellCustom
          text={document.highlightedDate !== null ? 'Yes' : 'No'}
        />
      )}
    </>
  );
};

interface IDocumentCardRenewalProps<TDocument extends IDocumentDTO> {
  document: TDocument;
  handleDocumentStatusChange?: (id: number, status: string) => void;
}

const DocumentCardRenewal = <TDocument extends IDocumentDTO>({
  document,
  handleDocumentStatusChange,
}: IDocumentCardRenewalProps<TDocument>): JSX.Element => {
  return (
    <>
      <TableImageCell
        text={
          document.name
            ? `${document.name}.${document?.extension ?? ''}`
            : document.originalName ?? 'unknown'
        }
        subtitle=""
        dataCy="document-card"
        type="icon"
      >
        <FileIcon />
      </TableImageCell>
      <TableButtonActionsCell dataCy={'button-actions-cell'}>
        <ButtonAction
          styleType="accept"
          textTransform="capitalize"
          fontWeight={500}
          disabled={false}
          $accept={document.status !== documentStatus.approved}
          onClick={(e) => {
            e.stopPropagation();
            if (document.status === documentStatus.approved) {
              return;
            }
            handleDocumentStatusChange?.(document.id, documentStatus.approved);
          }}
        >
          {document.status === documentStatus.approved
            ? documentStatus.approved
            : 'Approve'}
        </ButtonAction>
        <ButtonAction
          styleType="decline"
          textTransform="capitalize"
          fontWeight={500}
          disabled={false}
          $accept={document.status !== documentStatus.declined}
          onClick={(e) => {
            e.stopPropagation();
            if (document.status === documentStatus.declined) {
              return;
            }
            handleDocumentStatusChange?.(document.id, documentStatus.declined);
          }}
        >
          {document.status === documentStatus.declined
            ? documentStatus.declined
            : 'Decline'}
        </ButtonAction>
      </TableButtonActionsCell>

      <TableCell text={document?.documentType ?? ''} />
      <TableCell text={String(document?.createdBy?.fullName ?? '')} />
    </>
  );
};
