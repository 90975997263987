import { z } from 'zod';

export const webLinkSchema = z.object({
  title: z.string(),
  url: z.string(),
  target: z.enum(['_blank', '_self']),
});

export const documentLinkSchema = z.object({
  title: z.string(),
  href: z.string().min(1, 'Required'),
  target: z.enum(['_blank', '_self']),
});

export const emailLinkSchema = z.object({
  email: z.string().email('Invalid email address'),
  subject: z.string(),
  body: z.string(),
});

export type WebLinkSchema = z.infer<typeof webLinkSchema>;
export type DocumentLinkSchema = z.infer<typeof documentLinkSchema>;
export type EmailLinkSchema = z.infer<typeof emailLinkSchema>;
